import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import googlePlay from "../images/google-play-store.svg";
import appStore from "../images/app-store.svg";
import { Subscription } from "../components/partials/subscription/subscription";
import { PricingCard } from "../components/partials/pricing/pricingCard";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const Pricing = ({ data }) => {
  const { homepages, pricing } = data.strapi;
  const { language, title, pricingModels, meta } = pricing;
  const {
    header,
    footer,
    subscriptionHeading,
    subscriptionDescription,
  } = homepages[0];

  const disptach = useContext(GlobalDispatchContext);
  useEffect(() => {
    disptach({ type: "SET_LANGUAGE", language });
  });

  return (
    <Layout header={header} footer={footer}>
      <SEO title={meta.title} description={meta.description}/>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <div className="title-small border-blue mb-2 mb-md-5" >
              <h2 className="text-uppercase">{title}</h2>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-5">
          <PricingCard pricingModels={pricingModels} />
        </div>
        <div className="row">
          <div className="col-12 px-md-5 pb-4 text-center">
            <a
              className="p-0 my-btn-store m-2"
              href="https://play.google.com/store/apps/details?id=com.coopzapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="Google Play" src={googlePlay} width="auto" />
            </a>
            <a
              className="p-0 my-btn-store m-2"
              href="https://apps.apple.com/app/id1467176993"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="App Store" src={appStore} width="auto" />
            </a>
          </div>
        </div>
      </div>
      <Subscription
        subscriptionHeading={subscriptionHeading}
        subscriptionDescription={subscriptionDescription}
      />
    </Layout>
  )
}

export default Pricing

export const pageQuery = graphql`
  query Pricing($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      pricing(id: $id) {
        language
        title
        pricingModels {
          pricingType
          monthly
          monthlyPrice
          subscriptionPrice
          discount
          features
        }
        meta {
          title
          description
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
        subscriptionHeading
        subscriptionDescription
      }
    }
  }
`
